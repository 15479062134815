import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { baseURL } from "../../../service/API.js";
import "react-pagination-library/build/css/index.css";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import "../../component.css";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import Box from "@material-ui/core/Box";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Switch from "@material-ui/core/Switch";
import { getDay } from "date-fns";
export const CreatePromotion = () => {
  const { user } = useSelector((state) => state.auth);
  const subheader = useSubheader();
  subheader.setTitle("Promotion");
  const intl = useIntl();
  const history = useHistory();
  //Step & subStep
  const [currentStep, setCurrentStep] = useState(1);
  const [subCurrentPage, setSubCurrentPage] = useState(0);
  //promotion type
  const [promotionTypeId, setPromotionTypeId] = useState(8);
  const [promotionTypeName_en, setPromotionTypeNameEn] = useState(null);
  const [promotionTypeName_th, setPromotionTypeNameTh] = useState(null);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  //discount type
  const [discountTypeId, setDiscountTypeId] = useState(null);
  const [discountTypeName_en, setDiscountTypeNameEn] = useState(null);
  const [discountTypeName_th, setDiscountTypeNameTh] = useState(null);
  const [discountTargetId, setDiscountTargetId] = useState(null);

  const [menuGroup, setMenuGroup] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [groupMenuMap, setGroupMenuMap] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [promotionSet, setPromotionSet] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [originalGroupMenuMap, setOriginalGroupMenuMap] = useState({});
  let currentDate = new Date();
  const [startDate, setStartDate] = useState(currentDate);
  const [expiryDate, setExpiryDate] = useState(currentDate);
  //promotion detail
  const [promotionNameTH, setPromotionNameTH] = useState("");
  const [promotionNameEN, setPromotionNameEN] = useState("");
  const [promotionDescTH, setPromotionDescTH] = useState("");
  const [promotionDescEN, setPromotionDescEN] = useState("");
  const [amountValue, setAmountValue] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);

  const [eventPromotion, setEventPromotion] = useState([]);
  const [eventDiscount, setEventDiscount] = useState([]);
  //date and time
  const [dayType, setDayType] = useState("");
  const [dayTypeid, setDayTypeId] = useState(0);
  const [timeType, setTimeType] = useState("");
  const [timeTypeid, setTimeTypeId] = useState(0);

  const [expanded, setExpanded] = useState(null);
  //autoPromotion
  const [is_autoPromotion, setIsAutoPromotion] = useState(false);
  const [selectedAutoPromotion, setSelectedAutoPromotion] = useState(0);

  //itemLimit
  const [itemLimit, setItemLimit] = useState(false);
  const [item_limit_per_cus, setItemLimitPerCus] = useState(0);
  const [item_limit_per_day, setItemLimitPerDay] = useState(0);
  const [redemption_limit, setRedemptionLimit] = useState(0);
  const [is_redemption, setIsRedemption] = useState(false);
  const [isItemLimitPerCus, setIsItemLimitPerCus] = useState(false);
  //GenerateCode
  const [isGenerateCode, setIsGenerateCode] = useState(false);
  const [showTimeModal, setShowTimeModal] = useState(false);
  //PromotionChannel
  const [is_dinein, setIsDineIn] = useState(true);
  const [is_delivery, setIsDelivery] = useState(true);
  const [is_pickup, setIsPickup] = useState(true);
  const [checkedAllChannels, setCheckedAllChannels] = useState(true);
  //days
  const [showDayModal, setShowDayModal] = useState(false);
  const [days, setDays] = useState([]);
  const [selectedDaysShow, setSelectedDaysShow] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  //time
  const [selectedTimeShow, setSelectedTimeShow] = useState([]);
  //discount from & condition type
  const [discount_from_id, setDiscountFromID] = useState(12);
  const [condition_type_id, setConditionTypeID] = useState(10);
  const [discount_from, setDiscountFrom] = useState([]);
  const [condition_type, setConditionType] = useState([]);
  const [isByBill, setIsByBill] = useState(false)
  const [isByMenu, setIsByMenu] = useState(true)
  const [isQty, setIsQty] = useState(false)
  const [isRevenue, setIsRevenue] = useState(true)

  let initTimeSlot = {
    id: null,
    start_time: "00:00",
    close_time: "23:59",
  };
  const [timeSlots, setTimeSlots] = useState([]);
  const ratingButton = [
    { value: 1, label_th: "สูง", label_en: "High" },
    { value: 2, label_th: "", label_en: "" },
    { value: 3, label_th: "กลาง", label_en: "Middle" },
    { value: 4, label_th: "", label_en: "" },
    { value: 5, label_th: "ต่ำ", label_en: "Low" },
  ];
  const lang = localStorage.getItem("Language");

  const settingDaysStep = [
    {
      type: "day",
      name: "Selected days",
      name_th: "เลือกวัน",
      options: [
        {
          option_name_en: "Everyday restaurant is open",
          option_name_th: "ทุกวันที่ร้านเปิดทำการ",
        },
        {
          option_name_en: "Set specific days",
          option_name_th: "เลือกช่วงวันเฉพาะ",
        },
      ],
    },
  ];
  const settingTimesStep = [
    {
      type: "time",
      name: "Selected times",
      name_th: "เลือกเวลา",
      options: [
        {
          option_name_en: "All opening hours",
          option_name_th: "ทุกช่วงเวลา",
        },
        {
          option_name_en: "Set specific times",
          option_name_th: "เลือกช่วงเวลาเฉพาะ",
        },
      ],
    },
  ];

  const img1 = require('./../images/setItem1.png');
  const img2 = require('./../images/setItem2.png');
  const img3 = require('./../images/setItem3.png');
  const img4 = require('./../images/setItem4.png');

  const steps = [
    "PROMO TYPE",
    "DISCOUNT TYPE",
    "SET ITEMS",
    "SETTING",
    "SUMMARY",
  ];

  useEffect(() => {
    getGroup();
    getMenu();
    getPromotion();
    getDiscount();
    getDays();
    getAllSetting();
  }, []);
  useEffect(() => {
    // Filter days where the corresponding checkedday is true
    const selectedDays = days.filter(day => day.status);
    setSelectedDays(selectedDays)
    // Get the English names of the selected days
    const selectedDaysShow = selectedDays.map(day => day.datename_en);
    setSelectedDaysShow(selectedDaysShow)
  }, [days])
  useEffect(() => {
    const selectedTimeShow = timeSlots.map(time => `${time.start_time} - ${time.close_time}`).join(',');
    setSelectedTimeShow(selectedTimeShow)

  }, [timeSlots])
  const AlertError = (message) => {
    Swal.fire("!", message, "error");
  };
  const closeTimeModal = () => {
    setShowTimeModal(false);
  };
  const closeDayModal = () => {
    setShowDayModal(false);
  };
  const submitSelectedDay = () => {
    console.log("submit checkedDay=====>", days);
    setShowDayModal(false);
  };
  const handleChangeShowSelectedDay = () => {
    console.log("showDayModal===>", showDayModal);
    setShowDayModal(!showDayModal);
  };
  const handleChangeShowSelectedTime = () => {
    console.log("showTimeModal===>", showTimeModal);
    setShowTimeModal(!showTimeModal);
  };
  const handleDayCheckboxChange = (id) => {
    console.log("day========>", id);
    setDays((prevDayData) =>
      prevDayData.map((day) =>
        day.id === id ? { ...day, status: !day.status } : day
      )
    );
    console.log("day======>", days)


  };

  const addTimeSlot = () => {
    setTimeSlots([...timeSlots, { ...initTimeSlot }]);
  };
  const toggleAllChannels = (state) => {
    setIsDelivery(state);
    setIsPickup(state);
    setIsDineIn(state);
    setCheckedAllChannels(state);
  };

  const handleCheckAllChannels = () => {
    toggleAllChannels(!checkedAllChannels);
  };

  const handleCheckChannel = (channelState, setChannelState) => {
    setCheckedAllChannels(false);
    setChannelState(!channelState);
  };
  const handleSetDiscountFrom = (id) => {
    setIsByMenu(!isByMenu);
    setIsByBill(!isByBill);
    setDiscountFromID(id)
  }
  const handleSetConditionType = (id) => {
    console.log("ConditionType========>", id)
    setConditionTypeID(id)
    setIsRevenue(!isRevenue);
    setIsQty(!isQty);

  }

  const handleCheckDinein = () => handleCheckChannel(is_dinein, setIsDineIn);
  const handleCheckPickup = () => handleCheckChannel(is_pickup, setIsPickup);
  const handleCheckDelivery = () => handleCheckChannel(is_delivery, setIsDelivery);

  function validateDateTime(startDate, expiryDate) {
    const errors = [];
    const openTimeDate = startDate;
    const closeTimeDate = expiryDate;
    if (openTimeDate > closeTimeDate) {
      AlertError(
        `Error: start time (${openTimeDate}) is greater than expiry time (${closeTimeDate}) `
      );
      return false; // Stop further validation if an error is found
    }
    if (openTimeDate === closeTimeDate) {
      AlertError(
        `Error: Open time (${openTimeDate}) is the same time of close time (${closeTimeDate}) `
      );
      return false; // Stop further validation if an error is found
    }
    if (
      openTimeDate === null ||
      openTimeDate === undefined ||
      closeTimeDate === null ||
      closeTimeDate === undefined
    ) {
      AlertError(`Error: Open time  or closeTime is invalid `);
      return false; // Stop further validation if an error is found
    }

    return true; // Validation passed
  }
  function checkSpecificTime() {
    for (let i = 0; i < timeSlots.length - 1; i++) {
      if (timeSlots.is_delete == 1) {
        return true;

      } else {
        const currentCloseTime = new Date(`1970-01-01T${timeSlots[i].close_time}`);
        const currentOpenTime = new Date(`1970-01-01T${timeSlots[i].start_time}`);
        const nextOpenTime = new Date(`1970-01-01T${timeSlots[i + 1].start_time}`);

        if (currentCloseTime > nextOpenTime) {
          AlertError(`Error: Close time (${timeSlots[i].close_time}) overlaps with open time (${timeSlots[i + 1].start_time}) `);
          return false; // Stop further validation if an error is found
        }
        if (currentOpenTime == nextOpenTime) {
          AlertError(`Error: start time (${timeSlots[i].start_time}) overlaps with open time (${timeSlots[i + 1].start_time}) `);
          return false;

        }

      }

    }
    setShowTimeModal(false)
    return true;

  }
  const getPromotion = async () => {
    let apiUrls = `${baseURL}/management/promotionType`;
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        let newState = res.data.data.map((promotion) => ({
          text: promotion.name_en,
          description: promotion.desc_en,
          promotion_type_id: promotion.promotion_type_id,
          name_en: promotion.name_en,
          name_th: promotion.name_th,
          icon:
            promotion.promotion_type_id === 1
              ? "./media/icon/discount.jpg"
              : "./media/icon/present.jpg",
          options:
            promotion.options?.map((option) => ({
              text: option.name_en,
              promotion_type_id: option.promo_type_id,
            })) || [],
        }));
        setEventPromotion(newState);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDiscount = async () => {
    let apiUrls = `${baseURL}/management/discountType`;
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        let newState = res.data.data.map((discount) => ({
          title: discount.name_en,
          name_en: discount.name_en,
          name_th: discount.name_th,
          detail: discount.desc_en,
          discount_type_id: discount.discount_type_id,
          icon:
            discount.discount_type_id === 3
              ? "./media/icon/fixedprice.jpg"
              : "./media/icon/percent.jpg", // เพิ่ม icon ตาม discount_type_id
          options: discount.options.map((option) => ({
            text: option.name_en,
            detail: option.description_en,
            discount_target_id: option.discount_target_id,
          })),
        }));
        console.log("discount data======>", newState);
        setEventDiscount(newState);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllSetting = async () => {
    let apiUrls = `${baseURL}/management/settingType`;
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        let discountFrom = res.data.data.find(item => item.discount_from)?.discount_from;
        setDiscountFrom(discountFrom);
        let conditionType = res.data.data.find(item => item.condition_type)?.condition_type;
        setConditionType(conditionType);
      })
      .catch((err) => {
        console.log(err);
      });

  }

  const getGroup = async () => {
    let apiUrls = `${baseURL}/management/group?limit=${10000}&page=1`;
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        "Accept-Language": "th",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        let newState = res.data.data.map((group) => ({
          value: group.id,
          label: group.group_name,
        }));
        setMenuGroup(newState);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getMenu = async () => {
    let apiUrls = `${baseURL}/management/menu?limit=${10000}&page=1`;
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        let newState = res.data.data;
        setMenuItems(newState);
        let groupMenuMap = {};
        newState.forEach((menu) => {
          if (!groupMenuMap[menu.group_id]) {
            groupMenuMap[menu.group_id] = [];
          }
          groupMenuMap[menu.group_id].push(menu);
        });
        // Update item_count for each group
        setMenuGroup((prevMenuGroup) =>
          prevMenuGroup.map((group) => ({
            ...group,
            item_count: groupMenuMap[group.value]
              ? groupMenuMap[group.value].length
              : 0,
          }))
        );
        setGroupMenuMap(groupMenuMap);
        setOriginalGroupMenuMap(groupMenuMap); // Store original map for reset
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getDays = async () => {
    let apiUrls = `${baseURL}/management/days?page=1&limit=10`;
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        console.log("res.data.data========>", res.data.data);

        let dayData = [];
        let newState = res.data.data;
        newState.forEach(item => {
          dayData.push({
            id: item.id,
            datename_en: item.datename_en,
            datename_th: item.datename_th,
            status: false
          })
        });
        setDays(dayData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleNextStep = () => {
    if (currentStep === 2 && (!discountTypeId || !discountTargetId)) {
      return Swal.fire(
        "Create !",
        "You must select a discount type and target.",
        "error"
      );
    }
    if (currentStep === 3 && promotionSet.length === 0) {
      return Swal.fire(
        "Create !",
        "You must select at least one item.",
        "error"
      );
    }
    if (currentStep === 4 && subCurrentPage === 0) {
      setSubCurrentPage(subCurrentPage + 1);
      if (promotionNameTH == null || promotionNameTH == '') {
        setCurrentStep(4)
        setSubCurrentPage(0)
        return Swal.fire(
          "Invalid Promotion Name (th)",
          "Please fill Promotion Thai Name.",
          "error"
        );
      }
      if (promotionNameEN == null || promotionNameEN == '') {
        setCurrentStep(4)
        setSubCurrentPage(0)
        return Swal.fire(
          "Invalid Promotion Name (en)",
          "Please fill Promotion English Name.",
          "error"
        );
      }
    } else if (currentStep === 4 && subCurrentPage === 1) {
      const startDateTime = new Date(startDate);
      const expiryDateTime = new Date(expiryDate);
      if (expiryDateTime <= startDateTime) {
        return Swal.fire(
          "Invalid Dates",
          "Expiry date must be after start date.",
          "error"
        );
      }

    }

    if (currentStep < 4 || (currentStep == 4 && subCurrentPage == 1)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBackStep = () => {
    if (currentStep > 1) {
      console.log("currentStep========>", currentStep);
      if (currentStep === 2) {
        setDiscountTypeId(null);
        setDiscountTargetId(null);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setCurrentStep(currentStep - 1);
      } else if (currentStep === 3) {
        setPromotionSet([]);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setCurrentStep(currentStep - 1);
      } else if (currentStep === 4) {
        console.log("subcurrentStep========>", subCurrentPage);
        if (subCurrentPage > 0) {
          console.log("subcurrentPage > 0");
          setSubCurrentPage((prevsubcurrentPage) => prevsubcurrentPage - 1);

        } else {
          console.log("subCurrentPage = 0");
          //subCurrentPage = 0
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
          setSubCurrentPage((prevsubcurrentPage) => prevsubcurrentPage - 1);
          setCurrentStep(currentStep - 1);
        }
      }
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setCurrentStep(currentStep - 1);


    }
  };
  const handleChangeItemLimit = () => {
    setItemLimit(!itemLimit);
  };
  const handleChangeRedemption = () => {
    setIsRedemption(!is_redemption);
  };
  const handleChangeAutoPromotion = () => {
    setIsAutoPromotion(!is_autoPromotion);
  };
  const handleChangeItemLimitPerCus = () => {
    setIsItemLimitPerCus(!isItemLimitPerCus);
  };
  const handleChangeGenerateCode = () => {
    setIsGenerateCode(!isGenerateCode);
  };
  const handleSelect = (value) => {
    console.log("selectd value===>", value)
    setSelectedAutoPromotion(value);
  };
  const handleTimeChange = (e, index, field) => {
    const newTimeSlots = [...timeSlots];
    newTimeSlots[index][field] = e.target.value;
    setTimeSlots(newTimeSlots);
    console.log("time slot===========>", newTimeSlots)
  };
  const handleDeleteRow = (index) => {
    const newTimeSlots = timeSlots.filter((_, i) => i !== index);
    setTimeSlots(newTimeSlots);
  };

  const handlePromotionTypeSelect = (promotion_type_id) => {
    const selectedPromotion = eventPromotion.find(
      (promotion) => promotion.promotion_type_id === promotion_type_id
    );
    const promotionNameEn = selectedPromotion ? selectedPromotion.name_en : null;
    const promotionNameTh = selectedPromotion ? selectedPromotion.name_th : null;
    setPromotionTypeId(promotion_type_id);
    setPromotionTypeNameEn(promotionNameEn);
    setPromotionTypeNameTh(promotionNameTh);
    setSelectedOptionId(null); // Clear selectedOptionId when selecting a new promotion type
    if (selectedPromotion.options && selectedPromotion.options.length > 0) {
      setExpanded(promotion_type_id);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setCurrentStep(currentStep + 1);
    }
  };

  const handleOptionSelect = (option_id) => {
    setSelectedOptionId(option_id);
    setPromotionTypeId(option_id); // Set promotionTypeId to the selected option's ID
  };

  const handleDiscountTypeSelect = (discount_type_id, discount_target_id) => {

    const selectedDiscount = eventDiscount.find(
      (discount) => discount.discount_type_id === discount_type_id
    );
    const discountTypeNameEn = selectedDiscount ? selectedDiscount.name_en : null;
    const discountTypeNameTh = selectedDiscount ? selectedDiscount.name_th : null;
    setDiscountTypeId(discount_type_id);
    setDiscountTargetId(discount_target_id);
    setDiscountTypeNameEn(discountTypeNameEn);
    setDiscountTypeNameTh(discountTypeNameTh);
  };
  const handleDayTypeSelect = (dayType, dayTypeIndex) => {
    console.log("DayTypeSelect====>", dayType);
    setDayType(dayType);
    setDayTypeId(dayTypeIndex);
    if (dayType === "Everyday restaurant is open") {
      setSelectedDaysShow([])
    }
  };
  const handleTimeTypeSelect = (timeType, timeTypeIndex) => {
    console.log("TimeTypeSelect====>", timeType);
    if (timeType === "All opening hours") {
      setTimeSlots([]);
      setTimeType(timeType);
      setTimeTypeId(timeTypeIndex);
    } else {
      setTimeType(timeType);
      setTimeTypeId(timeTypeIndex);
      setTimeSlots([initTimeSlot])
    }
  };

  const handleMenuSelect = (menuId) => {
    let newPromotionSet = [...promotionSet];
    if (newPromotionSet.includes(menuId)) {
      newPromotionSet = newPromotionSet.filter((id) => id !== menuId);
    } else {
      newPromotionSet.push(menuId);
    }
    setPromotionSet(newPromotionSet);
  };

  const handleCategorySelect = (groupId) => {
    let newPromotionSet = [...promotionSet];
    if (newPromotionSet.includes(groupId)) {
      newPromotionSet = newPromotionSet.filter((id) => id !== groupId);
    } else {
      newPromotionSet.push(groupId);
    }
    setPromotionSet(newPromotionSet);
  };

  const toggleExpand = (index) => {
    if (expanded === index) {
      setExpanded(null);
      setSelectedOptionId(null); // Clear selectedOptionId when collapsing
    } else if (index === "day") {
      setExpanded("day");
    } else if (index === "time") {
      setExpanded("time");
    } else {
      setExpanded(index);
    }
  };

  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      setGroupMenuMap(originalGroupMenuMap);
      setExpanded(null);
    } else {
      const filteredMenu = {};
      Object.keys(originalGroupMenuMap).forEach((groupId) => {
        filteredMenu[groupId] = originalGroupMenuMap[groupId].filter((menu) =>
          menu.item_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
      setGroupMenuMap(filteredMenu);
      const firstGroupWithResults = Object.keys(filteredMenu).find(
        (groupId) => filteredMenu[groupId].length > 0
      );
      setExpanded(firstGroupWithResults || null); // Automatically expand the first group with search results
    }
  };

  const renderMenuItem = (menu) => {
    let discount = 0;
    if (discountTypeId === 3) {
      discount = discountValue;
    } else if (discountTypeId === 4) {
      discount = menu.price * (amountValue || 1) * (discountValue / 100);
    }
    const discountedPrice = menu.price * (amountValue || 1) - discount;
    const displayDiscountedPrice = discountedPrice > 0 ? discountedPrice : 0;

    const imageSrc = menu.item_image_path
      ? menu.item_image_path
      : "./media/icon/slash.jpg";

    return (
      <div key={menu.id} style={menuCardStyle}>
        <img
          src={imageSrc}
          alt={menu.item_name}
          style={menuImageStyle}
          onError={(e) => {
            e.target.src = "./media/icon/slash.jpg";
          }}
        />
        <p style={menuNameStyle}>
          {menu.item_name}
          {amountValue > 0 && ` (${amountValue} items)`}
        </p>
        {discount != 0 && discount != '' ?
          <>
            <p style={originalPriceStyle}>
              <s>฿{menu.price * (amountValue || 1)}</s>
            </p>
            <p style={discountedPriceStyle}>฿{displayDiscountedPrice.toFixed(2)}</p>
          </> :
          <>
            <p style={discountedPriceStyle}>
              <span>฿{menu.price * (amountValue || 1)}</span>
            </p>
          </>
        }

      </div>
    );
  };
  const renderMenuItem2 = (menu) => {
    let discount = 0;
    if (discountTypeId === 3) {
      discount = discountValue;
    } else if (discountTypeId === 4) {
      discount = menu.price * (amountValue || 1) * (discountValue / 100);
    }
    const discountedPrice = menu.price * (amountValue || 1) - discount;
    const displayDiscountedPrice = discountedPrice > 0 ? discountedPrice : 0;

    const imageSrc = menu.item_image_path
      ? menu.item_image_path
      : "./media/icon/slash.jpg";

    return (
      <div className="col-6 pt-4">
        <div
          key={menu.id}
          className="promotion-item d-flex p-4"
          style={{ textAlign: "left" }}
        >
          <img
            className="promotion-img"
            src={imageSrc}
            alt={menu.item_name}
            onError={(e) => {
              e.target.src = "./media/icon/slash.jpg";
            }}
          />
          <div style={menuNameStyle2}>
            <p >
              {menu.item_name}
              {amountValue > 0 && ` (${amountValue} items)`}
            </p>

            {discount != 0 && discount != '' ?
              <>
                <p className="d-flex flex-wrap">
                  <div style={originalPriceStyle} ><s>฿{menu.price * (amountValue || 1)}</s></div>
                  <div>฿{displayDiscountedPrice.toFixed(2)}</div>
                </p>
              </> :
              <>
                <p style={discountedPriceStyle}>
                  <span>฿{menu.price * (amountValue || 1)}</span>
                </p>
              </>
            }
          </div>
        </div>
      </div>
    );
  };
  const formatDateShow = (dateString) => {
    const date = new Date(dateString);

    // Get individual parts
    const dayName = date.toLocaleString('en-US', { weekday: 'short' });
    const monthName = date.toLocaleString('en-US', { month: 'short' });
    const day = date.getDate();
    const year = date.getFullYear();
    const time = date.toLocaleTimeString('en-US', { hour12: false });

    return `${dayName} ${monthName} ${day} ${year} ${time}`;
  };

  const menuCardStyle = {
    border: "1px solid #ccc",
    borderRadius: "10px",
    padding: "10px",
    textAlign: "center",
    margin: "10px",
    width: "150px",
  };
  const menuCardStyle2 = {
    border: "1px solid #ccc",
    borderRadius: "10px",
    padding: "10px",
    textAlign: "center",
    margin: "10px",
    width: "150px",
  };

  const menuImageStyle = {
    width: "100px",
    height: "100px",
    borderRadius: "8px",
  };
  // style={{
  //   marginTop: "15px",
  //   float: "left",
  //   marginLeft: "15px",
  // }}

  const menuNameStyle = {
    fontWeight: "bold",
    margin: "10px 0",
  };
  const menuNameStyle2 = {
    float: "left",
    marginLeft: "15px",
  };
  const originalPriceStyle = {
    color: "#AAAAAA",
  };

  const discountedPriceStyle = {
    fontWeight: "bold",
    color: "#000",
  };
  const handleSubmit = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to create this promotion?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, create it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const formatDate = (date) => {
          const d = new Date(date);
          const year = d.getFullYear();
          const month = String(d.getMonth() + 1).padStart(2, "0");
          const day = String(d.getDate()).padStart(2, "0");
          const hours = String(d.getHours()).padStart(2, "0");
          const minutes = String(d.getMinutes()).padStart(2, "0");
          const seconds = String(d.getSeconds()).padStart(2, "0");
          return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };
        if (!validateDateTime(formatDate(startDate), formatDate(expiryDate))) {
          return;
        }

        const payload = {
          shop_id: localStorage.getItem("shop_id"),
          branch_id: localStorage.getItem("branch_id"),
          promotion_name_th: promotionNameTH,
          promotion_name_en: promotionNameEN,
          promotion_desc_th: promotionDescTH,
          promotion_desc_en: promotionDescEN,
          start_date_time: formatDate(startDate),
          expiry_date_time: formatDate(expiryDate),
          promotion_type_id: String(promotionTypeId),
          discount_type_id: String(discountTypeId),
          discount_target_id: String(discountTargetId),
          promotion_set: promotionSet.map((id) => ({ object_id: id })),
          discount_value: discountTypeId === 3 ? discountValue : 0,
          discount_percent: discountTypeId === 4 ? discountValue : 0,
          discount_from_id: promotionTypeId === 8 || promotionTypeId === 9 ? discount_from_id : 0,
          condition_type_id: promotionTypeId === 8 || promotionTypeId === 9 ? condition_type_id : 0,
          amount_value: promotionTypeId === 8 || promotionTypeId === 9 ? amountValue : 0,
          days: selectedDays,
          time_slot: timeSlots,
          is_pickup: is_pickup,
          is_dinein: is_dinein,
          is_delivery: is_delivery,
          is_auto: is_autoPromotion,
          item_limit_per_cus: item_limit_per_cus !== 0 ? item_limit_per_cus : 0,
          item_limit_per_day: item_limit_per_day !== 0 ? item_limit_per_day : 0,
          redemption_limit: redemption_limit !== 0 ? redemption_limit : 0,
          order_importance: selectedAutoPromotion !== 0 ? selectedAutoPromotion : 0,
        };
        console.log("payload====>", payload)

        let token = localStorage.getItem("Token");
        axios
          .post(`${baseURL}/management/promotionnew`, payload, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            Swal.fire({
              title: "Success!",
              text: "Promotion created successfully.",
              icon: "success",
              confirmButtonText: "OK",
            }).then(() => {
              history.push("/Promotion");
            });
          })
          .catch((err) => {
            Swal.fire("Error!", "Failed to create promotion.", "error");
            console.log(err);
          });
      }
    });
  };

  return (
    <>
      <Box style={{ width: "100%" }}>
        <Stepper
          style={{ backgroundColor: "#EEF0F8" }}
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <div className="d-flex justify-content-center">
        {currentStep === 1 && (
          <Card style={{ width: "80%" }}>
            <CardHeader
              title={intl.formatMessage({
                id: "113441",
                defaultMessage: "Select Promotion Type",
              })}
            ></CardHeader>
            <CardBody>
              <div className="promotion-selection">
                {eventPromotion.length > 0 &&
                  eventPromotion.map((data) => (
                    <div key={data.promotion_type_id}>
                      <div
                        className="radio-card"
                        onClick={() =>
                          handlePromotionTypeSelect(data.promotion_type_id)
                        }
                      >
                        <div className="radio-content">
                          <div className="radio-icon">
                            <img
                              style={{ borderRadius: "50%" }}
                              src={data.icon}
                              alt={data.text}
                            />
                          </div>
                          <div className="radio-details">
                            {data.text}
                            <p>{data.description}</p>
                          </div>
                          {data.options && data.options.length > 0 && (
                            <div className="radio-arrow">
                              <i
                                className={`fa ${expanded === data.promotion_type_id
                                  ? "fa-chevron-down"
                                  : "fa fa-chevron-right"
                                  }`}
                              ></i>
                            </div>
                          )}
                        </div>
                        <input
                          type="radio"
                          className="planes-radio"
                          name="promotionType"
                          checked={
                            promotionTypeId === data.promotion_type_id ||
                            selectedOptionId === data.promotion_type_id
                          }
                          readOnly
                        />
                      </div>
                      {expanded === data.promotion_type_id &&
                        data.options &&
                        data.options.length > 0 && (
                          <div className="discount-type-selection">
                            {data.options.map((option) => (
                              <div
                                className="radio-card"
                                key={option.promotion_type_id}
                                onClick={() =>
                                  handleOptionSelect(option.promotion_type_id)
                                }
                              >
                                <div style={{ width: "70px" }}></div>
                                <input
                                  type="radio"
                                  name="optionType"
                                  checked={
                                    selectedOptionId ===
                                    option.promotion_type_id
                                  }
                                  readOnly
                                />
                                <div className="radio-content">
                                  <div className="radio-details">
                                    <strong>{option.text}</strong>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="d-flex justify-content-center mt-3">
                              <Button
                                variant="primary"
                                onClick={handleNextStep}
                                disabled={!selectedOptionId}
                              >
                                Next
                              </Button>
                            </div>
                          </div>
                        )}
                    </div>
                  ))}
              </div>
            </CardBody>
          </Card>
        )}
        {currentStep === 2 && (
          <Card style={{ width: "80%" }}>
            <CardHeader title="Select Type"></CardHeader>
            <CardBody>
              {eventDiscount.map((group, groupIndex) => (
                <div key={groupIndex}>
                  <div
                    className="group-header"
                    onClick={() => toggleExpand(groupIndex)}
                  >
                    <div
                      className="radio-card"
                      style={{ borderBottom: "none" }}
                    >
                      <div className="radio-content">
                        <div className="radio-icon">
                          <img
                            style={{ borderRadius: "50%" }}
                            src={group.icon}
                            alt={group.text}
                          />
                        </div>
                        <div className="radio-details">
                          <h5>{group.title}</h5>
                          <p>{group.detail}</p>
                        </div>
                        <div className="radio-arrow">
                          <i
                            className={`fa ${expanded === groupIndex
                              ? "fa-chevron-down"
                              : "fa fa-chevron-right"
                              }`}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  {expanded === groupIndex && group.options.length > 0 && (
                    <div className="discount-type-selection">
                      {group.options.map((option) => (
                        <div
                          className="radio-card"
                          key={option.discount_target_id}
                          onClick={() =>
                            handleDiscountTypeSelect(
                              group.discount_type_id,
                              option.discount_target_id
                            )
                          }
                        >
                          <div style={{ width: "70px" }}></div>
                          <input
                            type="radio"
                            name="discountType"
                            checked={
                              discountTypeId === group.discount_type_id &&
                              discountTargetId === option.discount_target_id
                            }
                            readOnly
                          />
                          <div className="radio-content">
                            <div className="radio-details">
                              <strong>{option.text}</strong>
                              <p>{option.detail}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </CardBody>
          </Card>
        )}
        {currentStep === 3 && (
          <Card style={{ width: "80%" }}>
            <CardHeader title="Set Discount Items">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Text Search"
                    style={{ width: "200px" }}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn-primary ml-2"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {menuGroup.map((group) => (
                <div key={group.value}>
                  <div className="group-header">
                    <div
                      className="radio-card"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: "none",
                      }}
                    >
                      <div className="radio-content">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {discountTargetId === 6 && (
                            <input
                              type="checkbox"
                              checked={promotionSet.includes(group.value)}
                              style={{ marginTop: "-5px" }}
                              onChange={() => handleCategorySelect(group.value)}
                            />
                          )}
                          <div className="radio-content">
                            <h5
                              style={{
                                fontWeight: "bold",
                                marginLeft:
                                  discountTargetId === 6 ? "10px" : "0px",
                              }}
                            >
                              {group.label}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div
                        className="item-count"
                        style={{ fontWeight: "bold" }}
                        onClick={() => toggleExpand(group.value)}
                      >
                        <p style={{ marginRight: "20px" }}>
                          {group.item_count} items
                        </p>
                      </div>
                      <div
                        className="radio-arrow"
                        onClick={() => toggleExpand(group.value)}
                      >
                        <i
                          className={`fa ${expanded === group.value
                            ? "fa-chevron-down"
                            : "fa fa-chevron-right"
                            }`}
                          style={{ marginTop: "-5px" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                  {expanded === group.value &&
                    groupMenuMap[group.value] &&
                    groupMenuMap[group.value].length > 0 && (
                      <div className="discount-item-selection">
                        {groupMenuMap[group.value].map((menu) => (
                          <div
                            className="checkbox-card"
                            key={menu.id}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "10px",
                              borderBottom: "1px solid #e0e0e0",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div style={{ width: "70px" }}></div>
                              {discountTargetId === 5 && (
                                <input
                                  type="checkbox"
                                  checked={promotionSet.includes(menu.id)}
                                  onChange={() => handleMenuSelect(menu.id)}
                                />
                              )}
                              <div
                                className="checkbox-content"
                                style={{
                                  marginLeft:
                                    discountTargetId === 5 ? "30px" : "100px",
                                }}
                              >
                                <strong>{menu.item_name}</strong>
                                <p style={{ color: "#AAAAAA" }}>
                                  ฿{menu.price}
                                </p>
                              </div>
                            </div>
                            {menu.item_image_path ? (
                              <img
                                src={menu.item_image_path}
                                alt={menu.item_name}
                                style={{
                                  width: "60px",
                                  height: "60px",
                                  marginRight: "55px",
                                }}
                                onError={(e) => {
                                  e.target.src = "./media/icon/slash.jpg";
                                }} // ดักการโหลดภาพไม่สำเร็จ
                              />
                            ) : (
                              <img
                                src="./media/icon/slash.jpg"
                                alt={menu.item_name}
                                style={{
                                  width: "60px",
                                  height: "60px",
                                  marginRight: "55px",
                                }}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                </div>
              ))}
            </CardBody>
          </Card>
        )}
        {currentStep === 4 && subCurrentPage === 0 && (
          <Card style={{ width: "80%" }}>
            <CardHeader title="Setting Promotion"></CardHeader>
            <CardBody style={{ display: "flex-column" }}>
              {promotionTypeId >= 8 && (
                <div className="" style={{ width: "100%", gap: "10px", display: "flex" }}>
                  {discount_from.map((item) => (
                    //discount from
                    <button key={item.id}
                      className={`btn-custom-3 ${isByMenu && item.id == '12' || isByBill && item.id == '13' ? "clicked" : ""}`}
                      style={{
                        height: "90px",
                        width: "170px",
                      }}
                      onClick={() => handleSetDiscountFrom(item.id)}
                    >
                      <div>
                        {" "}
                        <span
                          className="justify-content-center"
                          style={{ marginBottom: "10px" }}
                        >
                          {lang == "th" ? item.name_th : item.name_en}
                        </span>
                      </div>

                      <div>
                        <span className="justify-content-center" style={{ fontSize: "10px" }}>
                          (
                          {lang == "th" ? item.desc_th : item.desc_en}
                          )
                        </span>
                      </div>
                    </button>


                  ))}


                </div>
              )}
              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                  marginTop: "25px"
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  <h6>
                    Discount (
                    {discountTypeId === 3 ? "Fixed Price" : "Percentage"})
                  </h6>
                </p>
                <input
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={discountValue}
                  onChange={(e) => setDiscountValue(e.target.value.replace(/\D/g, ''))}
                />
              </div>
              {promotionTypeId >= 8 && (
                // condition type
                <>
                  <div className="d-flex " style={{ width: "100%", gap: "10px", display: "flex" }}>
                    {condition_type.map((item) => (
                      <button key={item.id}
                        className={`btn-custom-3 ${isRevenue && item.id == '10' || isQty && item.id == '11' ? "clicked" : ""}`}
                        style={{
                          height: "90px",
                          width: "170px",
                        }}
                        onClick={() => handleSetConditionType(item.id)}
                      >
                        <div>
                          {" "}
                          <span
                            className="justify-content-center"
                            style={{ marginBottom: "10px" }}
                          >
                            {lang == "th" ? item.name_th : item.name_en}

                          </span>
                        </div>

                        <div>
                          <span className="justify-content-center" style={{ fontSize: "10px" }}>
                            (
                            {lang == "th" ? item.desc_th : item.desc_en}
                            )
                          </span>
                        </div>
                      </button>
                    ))}
                  </div>

                  <div className="form-inline" style={{ marginBottom: "25px", marginTop: "25px" }}>
                    <p
                      style={{ marginTop: "15px", float: "left", width: "20%" }}
                    >
                      {intl.formatMessage({
                        id: "113482",
                        defaultMessage: "Quantity require for bundle",
                      })}
                      :{" "}
                    </p>
                    <input
                      style={{
                        height: "38px",
                        width: "530px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                      value={amountValue}
                      onChange={(e) => setAmountValue(e.target.value.replace(/\D/g, ''))}
                    />
                  </div>
                </>
              )}
              <div>
                <h6>Discount target</h6>
                <div className="d-flex flex-wrap">
                  {discountTargetId === 6 ?
                    promotionSet.map((groupId) =>
                      groupMenuMap[groupId]?.map((menu) =>
                        renderMenuItem(menu)
                      )
                    )
                    : promotionSet.map((id) => {
                      const selectedMenu = menuItems.find((m) => m.id === id) || {};
                      return renderMenuItem(selectedMenu);
                    })}
                </div>
              </div>
              <div className="mt-4">
                <h6>Promotion Details:</h6>
                <div
                  className="form-inline"
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <p
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    <span style={{ color: "red" }}>*</span>
                    {intl.formatMessage({
                      id: "113483",
                      defaultMessage: "Promotion Name (TH)",
                    })}
                    :{" "}
                  </p>
                  <input
                    style={{
                      height: "38px",
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    value={promotionNameTH}
                    onChange={(e) => setPromotionNameTH(e.target.value)}
                  />
                </div>
                <div
                  className="form-inline"
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <p
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    <span style={{ color: "red" }}>*</span>
                    {intl.formatMessage({
                      id: "113484",
                      defaultMessage: "Promotion Name (EN)",
                    })}
                    :{" "}
                  </p>
                  <input
                    style={{
                      height: "38px",
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    value={promotionNameEN}
                    onChange={(e) => setPromotionNameEN(e.target.value)}
                  />
                </div>
                <div
                  className="form-inline"
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <p
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    <span style={{ color: "red" }}>*</span>
                    {intl.formatMessage({
                      id: "113485",
                      defaultMessage: "Promotion Description (TH)",
                    })}
                    :{" "}
                  </p>
                  <input
                    style={{
                      height: "38px",
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    value={promotionDescTH}
                    onChange={(e) => setPromotionDescTH(e.target.value)}
                  />
                </div>
                <div
                  className="form-inline"
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <p
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    <span style={{ color: "red" }}>*</span>
                    {intl.formatMessage({
                      id: "113486",
                      defaultMessage: "Promotion Description (EN)",
                    })}
                    :{" "}
                  </p>
                  <input
                    style={{
                      height: "38px",
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    value={promotionDescEN}
                    onChange={(e) => setPromotionDescEN(e.target.value)}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        )}
        {currentStep === 4 && subCurrentPage === 1 && (
          <Card style={{ width: "80%" }}>
            <CardHeader
              title={intl.formatMessage({
                id: "113474",
                defaultMessage: "Set Condition",
              })}
            >
              <div className="d-flex justify-content-between align-items-center"></div>
            </CardHeader>
            <CardBody>
              <div
                className="container"
                style={{
                  border: "1px solid",
                  borderRadius: "20px",
                  borderColor: "#CECDCD",
                  marginBottom: "20px",
                }}
              >
                <div
                  className=""
                  style={{ marginTop: "25px", marginBottom: "25px" }}
                >
                  <div className="mt-4">
                    <h6>
                      {intl.formatMessage({
                        id: "113453",
                        defaultMessage: "Promotion Period",
                      })}
                    </h6>
                    <div className="container">
                      <div
                        className="form-inline"
                        style={{
                          justifyContent: "",
                        }}
                      >
                        <div className="d-flex">
                          <p
                            style={{
                              marginTop: "15px",
                              marginRight: "30px",
                              float: "left",
                              width: "20%",
                            }}
                          >
                            <span style={{ color: "red" }}>*</span>
                            {intl.formatMessage({
                              id: "113454",
                              defaultMessage: "Start",
                            })}
                            :{" "}
                          </p>
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            showTimeSelect
                            dateFormat="yyyy-MM-dd HH:mm:ss"
                            className="form-control"
                            style={{
                              height: "38px",
                              width: "30%",
                              flexGrow: "1",
                              backgroundColor: "#F4F6F9",
                              borderRadius: "5px",
                              borderWidth: "0",
                            }}
                          />
                        </div>
                        <div className="d-flex">
                          <p
                            style={{
                              marginTop: "15px",
                              marginRight: "30px",
                              float: "left",
                              width: "20%",
                            }}
                          >
                            <span style={{ color: "red" }}>*</span>
                            {intl.formatMessage({
                              id: "113455",
                              defaultMessage: "End",
                            })}
                            :{" "}
                          </p>
                          <DatePicker
                            selected={expiryDate}
                            onChange={(date) => setExpiryDate(date)}
                            showTimeSelect
                            dateFormat="yyyy-MM-dd HH:mm:ss"
                            className="form-control"
                            style={{
                              height: "38px",
                              width: "30%",
                              flexGrow: "1",
                              backgroundColor: "#F4F6F9",
                              borderRadius: "5px",
                              borderWidth: "0",
                            }}
                          />
                        </div>
                      </div>

                      <div className="form-inline">
                        <p
                          style={{
                            marginTop: "15px",
                            float: "left",
                            width: "20%",
                          }}
                        >
                          <span style={{ color: "red" }}>*</span>
                          {intl.formatMessage({
                            id: "113456",
                            defaultMessage: "Day",
                          })}
                          :{" "}
                        </p>
                        {settingDaysStep.map((days, daysIndex) => (
                          <div key={daysIndex}>
                            <div
                              className="group-header"
                              onClick={() => toggleExpand(days.type)}
                            >
                              <div
                                className="radio-card"
                                style={{ borderBottom: "none" }}
                              >
                                <div className="radio-content">
                                  <div className="radio-details">
                                    {lang == "th" ? days.name_th : days.name}
                                  </div>
                                  <div className="radio-arrow">
                                    <i
                                      className={`fa ${expanded === "day"
                                        ? "fa-chevron-up"
                                        : "fa fa-chevron-down"
                                        }`}
                                    ></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {expanded === "day" && days.options.length > 0 && (
                              <div className="discount-type-selection">
                                {days.options.map((option, daysOptionIndex) => (
                                  <div
                                    className="radio-card"
                                    key={daysOptionIndex}
                                    onClick={() =>
                                      handleDayTypeSelect(
                                        option.option_name_en,
                                        daysOptionIndex
                                      )
                                    }
                                  >
                                    <div style={{ width: "70px" }}></div>
                                    <input
                                      type="radio"
                                      name="dayType"
                                      checked={dayTypeid === daysOptionIndex}
                                      readOnly
                                    />
                                    {/* discountTypeId === group.discount_type_id && discountTargetId === option.discount_target_id */}
                                    <div className="radio-content">
                                      <div className="radio-details">
                                        {lang == "th"
                                          ? option.option_name_th
                                          : option.option_name_en}

                                        {/* <p>{option.detail}</p> */}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                {dayType === "Set specific days" ? (
                                  <div>
                                    <span
                                      className=" d-flex text-primary px-5 py-3 btn-custom"
                                      onClick={() =>
                                        handleChangeShowSelectedDay()
                                      }
                                    >
                                      {intl.formatMessage({
                                        id: "113452",
                                        defaultMessage: "selected days",
                                      })}
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                        <Modal show={showDayModal} onHide={closeDayModal}>
                          <Modal.Header closeButton>
                            <div className="row">
                              <Modal.Title>
                                {/* "113452" : "selected days", */}
                                <div className="mt-2 ml-3">
                                  {intl.formatMessage({
                                    id: "113452",
                                    defaultMessage: "selected days",
                                  })}{" "}
                                </div>
                              </Modal.Title>
                            </div>
                          </Modal.Header>

                          <Modal.Body>
                            <div className="container  mb-5">
                              <div className="">
                                <div className="mb-5">
                                  <i className="fa fa-clock"></i>
                                  <span className="ml-3">
                                    {intl.formatMessage({
                                      id: "113458",
                                      defaultMessage:
                                        "You can only select days when your restaurant is open",
                                    })}{" "}
                                  </span>
                                </div>

                                {days.map((day, dayIndex) => (
                                  <div
                                    style={{
                                      flexFlow: "column",
                                      marginBottom: "25px",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={day.status}
                                      onChange={() =>
                                        handleDayCheckboxChange(day.id)
                                      }
                                    />
                                    <span style={{ marginLeft: "25px" }}>
                                      <strong>{day.datename_en}</strong>
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="d-flex justify-content-center mt-3">
                              <Button
                                variant="secondary"
                                onClick={() => closeDayModal()}
                                style={{ marginRight: "10px" }}
                              >
                                Back
                              </Button>
                              <Button
                                variant="primary"
                                onClick={() => submitSelectedDay()}
                              >
                                Confirm
                              </Button>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                      <div
                        className="form-inline"
                        style={{
                          marginBottom: "25px",
                        }}
                      >
                        <p
                          style={{
                            marginTop: "15px",
                            float: "left",
                            width: "20%",
                          }}
                        >
                          <span style={{ color: "red" }}>*</span>
                          {intl.formatMessage({
                            id: "113457",
                            defaultMessage: "Time",
                          })}
                          :{" "}
                        </p>
                        {settingTimesStep.map((times, timeIndex) => (
                          <div key={timeIndex}>
                            <div
                              className="group-header"
                              onClick={() => toggleExpand(times.type)}
                            >
                              <div
                                className="radio-card"
                                style={{ borderBottom: "none" }}
                              >
                                <div className="radio-content">
                                  <div className="radio-details">
                                    {lang == "th" ? times.name_th : times.name}
                                  </div>
                                  <div className="radio-arrow">
                                    <i
                                      className={`fa ${expanded === "time"
                                        ? "fa-chevron-up"
                                        : "fa fa-chevron-down"
                                        }`}
                                    ></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {expanded === "time" && times.options.length > 0 && (
                              <div className="discount-type-selection">
                                {times.options.map(
                                  (option, timeOptionIndex) => (
                                    <div
                                      className="radio-card"
                                      key={timeOptionIndex}
                                      onClick={() =>
                                        handleTimeTypeSelect(
                                          option.option_name_en,
                                          timeOptionIndex
                                        )
                                      }
                                    >
                                      <div style={{ width: "70px" }}></div>
                                      <input
                                        type="radio"
                                        name="discountType"
                                        checked={timeTypeid === timeOptionIndex}
                                        readOnly
                                      />
                                      <div className="radio-content">
                                        <div className="radio-details">
                                          {lang == "th"
                                            ? option.option_name_th
                                            : option.option_name_en}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                            {timeType === "Set specific times" ? (
                              <div>
                                <span
                                  className=" d-flex text-primary px-5 py-3 btn-custom"
                                  onClick={() => handleChangeShowSelectedTime()}
                                >
                                  {intl.formatMessage({
                                    id: "113451",
                                    defaultMessage: "selected times",
                                  })}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ))}
                        <Modal show={showTimeModal} onHide={closeTimeModal}>
                          <Modal.Header closeButton>
                            <div className="row">
                              <Modal.Title>
                                <div className="mt-2 ml-3">
                                  {intl.formatMessage({
                                    id: "113451",
                                    defaultMessage: "selected times",
                                  })}{" "}
                                </div>
                              </Modal.Title>
                            </div>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="">
                              <div className="container mt-3 mb-5">
                                <div className="mb-5">
                                  <i className="fa fa-clock"></i>
                                  <span className="ml-3">
                                    {intl.formatMessage({
                                      id: "113459",
                                      defaultMessage:
                                        "Customers will only see this promotion within the opening hours of your chosen days",
                                    })}{" "}
                                  </span>
                                </div>

                                {timeSlots.map((slot, index) => (
                                  <div
                                    keys={index}
                                    className="justify-content-center d-flex flex-wrap"
                                    style={{ flex: "1 0 21%" }}
                                  >
                                    <div className=" mb-3">
                                      <input
                                        type="time"
                                        className="form-control"
                                        value={slot.start_time}
                                        onChange={(e) =>
                                          handleTimeChange(
                                            e,
                                            index,
                                            "start_time"
                                          )
                                        }
                                      />
                                    </div>

                                    <div className="">
                                      <input
                                        type="time"
                                        className="form-control"
                                        value={slot.close_time}
                                        onChange={(e) =>
                                          handleTimeChange(
                                            e,
                                            index,
                                            "close_time"
                                          )
                                        }
                                      />
                                    </div>

                                    <div className="ml-3">
                                      <button
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={() => handleDeleteRow(index)}
                                      >
                                        {intl.formatMessage({
                                          id: "113011",
                                          defaultMessage: "Delete",
                                        })}
                                      </button>
                                    </div>
                                  </div>
                                ))}
                                <div className="text-right">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => addTimeSlot()}
                                  >
                                    {intl.formatMessage({
                                      id: "113449",
                                      defaultMessage: "Add Time Slot",
                                    })}
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-center mt-3">
                              <Button
                                variant="secondary"
                                onClick={() => closeTimeModal()}
                                style={{ marginRight: "10px" }}
                              >
                                Back
                              </Button>
                              <Button
                                variant="primary"
                                onClick={() => checkSpecificTime()}
                              >
                                Confirm
                              </Button>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*----------promotion limit------------ */}
              <div
                className="container"
                style={{
                  border: "1px solid",
                  borderRadius: "20px",
                  borderColor: "#CECDCD",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              >
                <div
                  className=""
                  style={{ marginTop: "25px", marginBottom: "25px" }}
                >
                  <div className="mt-4">
                    <h6>
                      {intl.formatMessage({
                        id: "113461",
                        defaultMessage: "Add Time Slot",
                      })}
                    </h6>
                    <div className="container">
                      <div>
                        <div
                          className="d-flex flex-wrap"
                          style={{ flexFlow: "flex-start" }}
                        >
                          <p
                            style={{
                              marginTop: "15px",
                              float: "left",
                              width: "20%",
                            }}
                          >
                            {intl.formatMessage({
                              id: "113462",
                              defaultMessage: "Redemption Limit",
                            })}
                            :{" "}
                          </p>
                          <div style={{ flexFlow: "column" }}>
                            <Switch
                              checked={is_redemption}
                              onChange={() => handleChangeRedemption()}
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                            />
                            {is_redemption && (
                              <div>
                                <div
                                  className=""
                                  style={{
                                    width: "530px",
                                  }}
                                >
                                  <input
                                    name="redemption_limit"
                                    type="text"
                                    min="0"
                                    style={{
                                      height: "38px",
                                      width: "350px",
                                      flexGrow: "1",
                                      backgroundColor: "#F4F6F9",
                                      borderRadius: "5px",
                                      borderWidth: "0",
                                    }}
                                    value={redemption_limit}
                                    onChange={(redemption_limit) =>
                                      setRedemptionLimit(
                                        redemption_limit.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className="d-flex flex-wrap"
                        style={{ flexFlow: "flex-start" }}
                      >
                        <p
                          style={{
                            marginTop: "15px",
                            float: "left",
                            width: "20%",
                          }}
                        >
                          {intl.formatMessage({
                            id: "113463",
                            defaultMessage: "Items limit per day",
                          })}
                          :{" "}
                        </p>
                        <div>
                          <Switch
                            checked={itemLimit}
                            onChange={() => handleChangeItemLimit()}
                          />

                          {itemLimit && (
                            <div>
                              <div>
                                <input
                                  name="item_limit_per_day"
                                  type="text"
                                  min="0"
                                  style={{
                                    height: "38px",
                                    width: "350px",
                                    flexGrow: "1",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                  }}
                                  value={item_limit_per_day}
                                  onChange={(item_limit_per_day) =>
                                    setItemLimitPerDay(
                                      item_limit_per_day.target.value
                                    )
                                  }
                                />
                                <div style={{ flexFlow: "column" }}>
                                  <p
                                    style={{
                                      marginTop: "15px",
                                      float: "left",
                                      width: "35%",
                                    }}
                                  >
                                    {intl.formatMessage({
                                      id: "113473",
                                      defaultMessage:
                                        "Items Limit Per Customer",
                                    })}
                                    :{" "}
                                  </p>
                                  <Switch
                                    checked={isItemLimitPerCus}
                                    onChange={() =>
                                      handleChangeItemLimitPerCus()
                                    }
                                  />
                                  {isItemLimitPerCus && (
                                    <div>
                                      <div
                                        className=""
                                        style={{
                                          width: "530px",
                                        }}
                                      >
                                        <input
                                          name="item_limit_per_cus"
                                          type="text"
                                          min="0"
                                          className="pl-5"
                                          style={{
                                            height: "38px",
                                            width: "150px",
                                            flexGrow: "1",
                                            backgroundColor: "#F4F6F9",
                                            borderRadius: "5px",
                                            borderWidth: "0",
                                          }}
                                          value={item_limit_per_cus}
                                          onChange={(item_limit_per_cus) =>
                                            setItemLimitPerCus(
                                              item_limit_per_cus.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*----------Auto Promotion------------ */}
              <div
                className="container"
                style={{
                  border: "1px solid",
                  borderRadius: "20px",
                  borderColor: "#CECDCD",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              >
                <div
                  className=""
                  style={{ marginTop: "25px", marginBottom: "25px" }}
                >
                  <div className="mt-4">
                    <h6>
                      {intl.formatMessage({
                        id: "113464",
                        defaultMessage: "Auto Promotion",
                      })}
                    </h6>
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: "space-between",
                        flexFlow: "row wrap",
                      }}
                    >
                      <div className="d-flex" style={{ width: "530px" }}>
                        <Switch
                          checked={is_autoPromotion}
                          onChange={() => handleChangeAutoPromotion()}
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />

                        <p
                          style={{
                            marginTop: "15px",
                            float: "left",
                            width: "20%",
                          }}
                        >
                          {intl.formatMessage({
                            id: "113464",
                            defaultMessage: "Auto Promotion",
                          })}
                        </p>
                      </div>

                      <div
                        className="d-flex"
                        style={{ justifyContent: "text-end" }}
                      >
                        {is_autoPromotion &&
                          ratingButton.map((item) => (
                            <div
                              key={item.value}
                              className={`circle ${selectedAutoPromotion === item.value
                                ? "selected"
                                : ""
                                }`}
                              onClick={() => handleSelect(item.value)}
                            >
                              <div className="">
                                <span className="circle-number">
                                  {item.value}
                                </span>
                              </div>

                              {item.label_th && lang == "th" ? (
                                <span
                                  className=""
                                  style={{
                                    position: "absolute",
                                    paddingTop: "75px",
                                  }}
                                >
                                  {item.label_th}
                                </span>
                              ) : (
                                <span
                                  className=""
                                  style={{
                                    position: "absolute",
                                    paddingTop: "75px",
                                  }}
                                >
                                  {item.label_en}
                                </span>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*----------Set sale channels for promotion------------ */}
              <div
                className="container"
                style={{
                  border: "1px solid",
                  borderRadius: "20px",
                  borderColor: "#CECDCD",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              >
                <div style={{ marginTop: "25px", marginBottom: "25px" }}>
                  <div className="mt-4">
                    <h6>
                      {" "}
                      {intl.formatMessage({
                        id: "113460",
                        defaultMessage: "Set sale channels promotion",
                      })}
                    </h6>
                    <input
                      type="checkbox"
                      checked={checkedAllChannels}
                      onChange={() => handleCheckAllChannels()}
                    />
                    <span className="justify-content-center ml-5">
                      {intl.formatMessage({
                        id: "113465",
                        defaultMessage: "set promotion for all channels",
                      })}
                    </span>

                    <div className="container" style={{ flexFlow: "column" }}>
                      <div className="form-inline">
                        <input
                          type="checkbox"
                          checked={is_dinein}
                          onChange={handleCheckDinein}
                        />
                        <p
                          style={{
                            marginTop: "15px",
                            float: "left",
                            width: "20%",
                          }}
                        >
                          {intl.formatMessage({
                            id: "113466",
                            defaultMessage: "Dine-in",
                          })}
                        </p>
                      </div>
                      <div className="form-inline">
                        <input
                          type="checkbox"
                          checked={is_pickup}
                          onChange={handleCheckPickup}
                        />
                        <p
                          style={{
                            marginTop: "15px",
                            float: "left",
                            width: "20%",
                          }}
                        >
                          {intl.formatMessage({
                            id: "113356",
                            defaultMessage: "Pick up",
                          })}
                        </p>
                      </div>
                      <div className="form-inline">
                        <input
                          type="checkbox"
                          checked={is_delivery}
                          onChange={handleCheckDelivery}
                        />
                        <p
                          style={{
                            marginTop: "15px",
                            float: "left",
                            width: "20%",
                          }}
                        >
                          {intl.formatMessage({
                            id: "113321",
                            defaultMessage: "Delivery",
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*----------generate code------------ */}
              <div
                className="container"
                style={{
                  border: "1px solid",
                  borderRadius: "20px",
                  borderColor: "#CECDCD",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              >
                <div
                  className=""
                  style={{ marginTop: "25px", marginBottom: "25px" }}
                >
                  <div className="mt-4">
                    <h6>
                      {" "}
                      {intl.formatMessage({
                        id: "113471",
                        defaultMessage: "Generate Code",
                      })}
                    </h6>
                    <div className="container">
                      <div className="form-inline">
                        <p
                          style={{
                            marginTop: "15px",
                            float: "left",
                            width: "30%",
                          }}
                        >
                          {intl.formatMessage({
                            id: "113472",
                            defaultMessage: "generate for this promotion",
                          })}
                          :{" "}
                        </p>
                        <Switch
                          checked={isGenerateCode}
                          onChange={() => handleChangeGenerateCode()}
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </div>
                      {isGenerateCode && (
                        <div>
                          <div
                            style={{
                              width: "530px",
                            }}
                          >
                            <input
                              name="redemption_limit"
                              type="text"
                              min="0"
                              style={{
                                height: "38px",
                                flexGrow: "1",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                              }}
                            // onChange={(redemption_limit) =>
                            //   setRedemptionLimit(
                            //     redemption_limit.target.value
                            //   )
                            // }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-3">
                {/* <Button
                  variant="secondary"
                  onClick={handleBackStep}
                  style={{ marginRight: "10px" }}
                >
                  Back
                </Button>
                <Button variant="primary" onClick={handleNext}>
                  Submit
                </Button> */}
              </div>
            </CardBody>
          </Card>
        )}
        {currentStep === 5 && (
          <Card style={{ width: "80%" }}>
            <CardHeader title="Summary">
              <div className="d-flex justify-content-between align-items-center">
                {/* <h5 className="mb-0">Summary</h5> */}
              </div>
            </CardHeader>
            <CardBody>
              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  {intl.formatMessage({
                    id: "113484",
                    defaultMessage: "Promotion Name (EN)",
                  })}
                  :{" "}
                </p>
                <input
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={
                    lang == "th" ? promotionTypeName_th : promotionTypeName_en
                  }
                  disabled
                  readOnly
                />
              </div>
              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Discount Type:
                </p>
                <input
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={
                    lang == "th" ? discountTypeName_th : discountTypeName_en
                  }
                  disabled
                  readOnly
                />
              </div>
              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                  position: "relative",
                }}
              >
                <div
                  className=""
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  <p style={{ position: "", top: 20 }}>Set Items:</p>
                </div>
                <div className="row">
                  {discountTargetId === 6
                    ? promotionSet.map((groupId) =>
                      groupMenuMap[groupId]?.map((menu) =>
                        renderMenuItem2(menu)
                      )
                    )
                    : promotionSet.map((id) => {
                      const selectedMenu =
                        menuItems.find((m) => m.id === id) || {};
                      return renderMenuItem2(selectedMenu);
                    })}
                </div>
              </div>
              {/* ---------------promotion name------------- */}
              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  {intl.formatMessage({
                    id: "113484",
                    defaultMessage: "Promotion Name (EN)",
                  })}
                  :{" "}
                </p>
                <input
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={promotionNameEN}
                  readOnly
                />
              </div>
              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  {intl.formatMessage({
                    id: "113483",
                    defaultMessage: "Promotion Name (TH)",
                  })}
                  :{" "}
                </p>
                <input
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={promotionNameTH}
                  readOnly
                />
              </div>
              {/* ---------------Description------------- */}

              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Description (en):
                </p>
                <input
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={promotionDescEN}
                  readOnly
                />
              </div>
              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Description (th):
                </p>
                <input
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={promotionDescTH}
                  readOnly
                />
              </div>
              {/* ---------------Quantity require------------- */}
              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Quantity required for bundle:
                </p>
                <input
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={"2"}
                  readOnly
                />
              </div>
              {/* ---------------Discount Amount------------- */}
              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Discount amount:
                </p>
                <input
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={discountValue}
                  readOnly
                />
              </div>
              {/* ---------------Promotion period------------- */}
              <div>
                <h6>
                  {intl.formatMessage({
                    id: "113445",
                    defaultMessage: "Promotion Period",
                  })}
                  :{" "}
                </h6>
                <div
                  className="form-inline"
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <p
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    {intl.formatMessage({
                      id: "start_date_time",
                      defaultMessage: "Promotion Period",
                    })}
                    :{" "}
                  </p>
                  <input
                    style={{
                      height: "38px",
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    value={`${formatDateShow(startDate)}-${formatDateShow(
                      expiryDate
                    )}`}
                    readOnly
                  />
                </div>
                <div
                  className="form-inline"
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <p
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    {intl.formatMessage({
                      id: "113476",
                      defaultMessage: "Days",
                    })}
                    :{" "}
                  </p>
                  <input
                    style={{
                      height: "38px",
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    value={
                      selectedDaysShow.length > 0
                        ? selectedDaysShow.join(", ")
                        : "Every day the restaurant is open"
                    }
                    readOnly
                  />
                </div>
                <div
                  className="form-inline"
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <p
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    {intl.formatMessage({
                      id: "113475",
                      defaultMessage: "Time",
                    })}
                    :{" "}
                  </p>
                  <input
                    style={{
                      height: "38px",
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    value={
                      selectedTimeShow ? selectedTimeShow : "All opening hours"
                    }
                    readOnly
                  />
                </div>
              </div>

              {/* ---------------Options------------- */}
              <div>
                <h6>Promotion period :</h6>
                <div
                  className="form-inline"
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <p
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    {intl.formatMessage({
                      id: "113477",
                      defaultMessage: "Customers criteria",
                    })}
                    :{" "}
                  </p>
                  <input
                    style={{
                      height: "38px",
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    value={is_autoPromotion == true ? "Auto Promotion" : "Manual Promotion"}
                    readOnly
                  />
                </div>
                <div
                  className="form-inline"
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <p
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    {intl.formatMessage({
                      id: "113478",
                      defaultMessage: "Maximum number of redemptions",
                    })}
                    :{" "}
                  </p>
                  <input
                    style={{
                      height: "38px",
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    value={redemption_limit > 0 ? redemption_limit : "No limit"}
                    readOnly
                  />
                </div>
                <div
                  className="form-inline"
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <p
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    {intl.formatMessage({
                      id: "113479",
                      defaultMessage: "Maximum Item Limit Per Day",
                    })}
                    :{" "}
                  </p>
                  <input
                    style={{
                      height: "38px",
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    value={
                      item_limit_per_day > 0
                        ? item_limit_per_day
                        : intl.formatMessage({
                          id: "113479",
                          defaultMessage: "No limit",
                        })
                    }
                    readOnly
                  />
                </div>
                <div
                  className="form-inline"
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <p
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    {intl.formatMessage({
                      id: "113481",
                      defaultMessage: "Maximum redemptions per customer",
                    })}
                    :{" "}
                  </p>
                  <input
                    style={{
                      height: "38px",
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    value={
                      item_limit_per_cus > 0
                        ? item_limit_per_cus
                        : intl.formatMessage({
                          id: "113479",
                          defaultMessage: "No limit",
                        })
                    }
                    readOnly
                  />
                </div>
              </div>
              <div className="d-flex justify-content-center mt-3">
                <Button
                  variant="secondary"
                  onClick={handleBackStep}
                  style={{ marginRight: "10px" }}
                >
                  Back
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              </div>
            </CardBody>
          </Card>
        )}
      </div>
      {currentStep > 1 && currentStep <= 4 && (
        <div className="d-flex justify-content-center mt-3">
          <Button
            variant="secondary"
            onClick={handleBackStep}
            style={{ marginRight: "10px" }}
          >
            Back
          </Button>
          <Button variant="primary" onClick={handleNextStep}>
            Next
          </Button>
        </div>
      )}
    </>
  );
};
